/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-down-left': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 13.5a.5.5 0 00.5.5h6a.5.5 0 000-1H3.707L13.854 2.854a.5.5 0 00-.708-.708L3 12.293V7.5a.5.5 0 00-1 0z"/>',
    },
});
